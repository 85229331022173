<template>
  <div class="w-full">
    <vs-divider v-if="alertBanner.length > 0" class="hidden md:block mt-0" />
    <div v-if="alertBanner.length > 0" class="my-3">
      <carousel
        :perPage="1"
        :paginationEnabled="false"
        :loop="true"
        :navigateTo="navigateTo"
      >
        <slide v-for="(slide, key) in alertBanner" :key="key">
          <div class="hidden md:flex items-center justify-between pl-2 md:px-5">
            <vs-icon
              icon="arrow_back_ios"
              size="small"
              class="hidden md:block mr-4 cursor-pointer"
              @click="handleBack(key)"
            ></vs-icon>
            <vs-icon
              icon="arrow_back_ios"
              size="15px"
              class="md:hidden mr-4 cursor-pointer"
              @click="handleBack(key)"
            ></vs-icon>

            <span
              @click="handleSelectBanner(slide)"
              class="mx-2 w-full text-center cursor-pointer hover:underline text-sm md:text-base text-black font-medium"
              >{{ slide.title }}</span
            >

            <vs-icon
              icon="arrow_forward_ios"
              size="small"
              class="hidden md:block cursor-pointer"
              @click="handleNext(key)"
            ></vs-icon>
            <vs-icon
              icon="arrow_forward_ios"
              size="15px"
              class="md:hidden mr-1 cursor-pointer"
              @click="handleNext(key)"
            ></vs-icon>
          </div>

          <!-- MOBILE -->

          <div class="flex md:hidden items-center justify-between pl-2 md:px-5">
            <vs-icon
              icon="arrow_back_ios"
              size="small"
              class="hidden md:block mr-4 cursor-pointer"
              @click="handleBack(key)"
            ></vs-icon>
            <vs-icon
              icon="arrow_back_ios"
              size="15px"
              class="md:hidden mr-4 cursor-pointer"
              @click="handleBack(key)"
            ></vs-icon>

            <span
              @click="handleSelectBanner(slide)"
              class="mx-2 w-full text-center cursor-pointer hover:underline text-sm md:text-base text-black font-medium"
              >{{ slide.title }}</span
            >

            <vs-icon
              icon="arrow_forward_ios"
              size="small"
              class="hidden md:block mr-4 cursor-pointer"
              @click="handleNext(key)"
            ></vs-icon>
            <vs-icon
              icon="arrow_forward_ios"
              size="15px"
              class="md:hidden mr-1 cursor-pointer"
              @click="handleNext(key)"
            ></vs-icon>
          </div>
        </slide>
      </carousel>
      <vs-popup
        class="learnMorePopup"
        :title="selectedBanner.title"
        :fullscreen="isMobileView ? true : false"
        :active.sync="learnMorePopupActive"
        :button-close-hidden="true"
      >
        <div class="p-5">
          <div
            class="text-center hidden md:block"
            v-if="selectedBanner.images && selectedBanner.images.desktop"
          >
            <img
              width="50%"
              :src="
                (selectedBanner.images && selectedBanner.images.desktop) || ''
              "
              alt="image"
            />
          </div>
          <div
            class="text-center block md:hidden"
            v-if="selectedBanner.images && selectedBanner.images.mobile"
          >
            <img
              width="50%"
              :src="
                (selectedBanner.images && selectedBanner.images.mobile) || ''
              "
              alt="image"
            />
          </div>
          <div class="text-center text-3xl my-8">
            {{ selectedBanner.title }}
          </div>
          <div
            class="alert-description"
            v-html="selectedBanner.description"
            style="font-size: 14px; margin-top: 4px"
          ></div>
          <div class="text-center mt-4 mb-8">
            <vs-button
              :color="selectedBanner.alert_type"
              @click="learnMorePopupActive = false"
              >I Understand</vs-button
            >
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    page: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
  },
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    alertId: "",
    selectedBanner: {},
    alertBanner: [],
    learnMorePopupActive: false,
    isMobileView: null,
    navigateTo: 0,
  }),
  methods: {
    ...mapActions("storeAlertNotification", [
      "fetchStoreAlertsBanner",
      "fetchStoreAlertsBannerByCategoryId",
      "fetchStoreAlertsBannerByBrand",
      "fetchStoreAlertsBannerByProductId",
      "fetchCheckoutStoreAlertsBanner",
    ]),
    async fetchAndSortAlerts(fetchMethod, payload = null) {
      try {
        const res = payload ? await fetchMethod(payload) : await fetchMethod();
        const sortedAlerts = this.sortedData(res.data.data);

        return sortedAlerts.map((alert) => {
          if (alert.alert_type === "success") alert.alert_type = "primary";
          if (alert.alert_type === "error") alert.alert_type = "danger";
          return alert;
        });
      } catch (error) {
        console.error(error.message);
        return [];
      }
    },
    updateHomepage(showAlert) {
      var element = document.getElementsByClassName("router-content");
      if (showAlert) {
        element[0].classList.add("shop-alert-margin");
        element[0].classList.remove("shop-margin");
      } else {
        element[0].classList.add("shop-margin");
        element[0].classList.remove("shop-alert-margin");
      }
    },
    sortedData(alerts) {
      return [...alerts].sort((a, b) => {
        if (a.priorityLevel == null && b.priorityLevel == null) {
          return 0;
        }
        if (a.priorityLevel == null) {
          return 1;
        }
        if (b.priorityLevel == null) {
          return -1;
        }
        return a.priorityLevel - b.priorityLevel;
      });
    },
    async getBanners() {
      try {
        const items = JSON.parse(
          JSON.stringify(
            this.$store.state.eCommerce.cartItems.slice().reverse()
          )
        );
        const productId = [...items].map((item) => item.productStoreId);

        const res = await this.fetchCheckoutStoreAlertsBanner({
          productId: productId,
        });

        const tempData = this.sortedData(res.data.data);
        tempData.map((alert) => {
          if (alert.alert_type === "success") alert.alert_type = "primary";
          if (alert.alert_type === "error") alert.alert_type = "danger";
          return alert;
        });
        this.alertBanner = tempData;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAlertByProduct() {
      this.alertBanner = await this.fetchAndSortAlerts(
        this.fetchStoreAlertsBannerByProductId,
        this.$route.params.id
      );
    },
    async fetchAlertByBrand() {
      this.alertBanner = await this.fetchAndSortAlerts(
        this.fetchStoreAlertsBannerByBrand,
        this.slug
      );
    },
    async fetchAlertByCategory() {
      this.alertBanner = await this.fetchAndSortAlerts(
        this.fetchStoreAlertsBannerByCategoryId,
        this.slug
      );
    },
    async fetchAlertDetails() {
      this.alertBanner = await this.fetchAndSortAlerts(
        this.fetchStoreAlertsBanner,
        {
          type: "homepage",
        }
      );
    },
    handleSelectBanner(details) {
      this.selectedBanner = { ...details };
      this.learnMorePopupActive = true;
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    handleBack(index) {
      if (index !== 0) this.navigateTo = this.navigateTo - 1;
    },
    handleNext(index) {
      if (index !== this.alertBanner.length - 1)
        this.navigateTo = this.navigateTo + 1;
      if (index === this.alertBanner.length - 1) this.navigateTo = 0;
    },
    fetchAlertBanners() {
      const fetchMap = {
        homepage: this.fetchAlertDetails,
        promotions: this.fetchAlertDetails,
        "fresh-life": this.fetchAlertDetails,
        category: this.fetchAlertByCategory,
        brand: this.fetchAlertByBrand,
        productDetail: this.fetchAlertByProduct,
      };

      const fetchMethod = fetchMap[this.page];
      return fetchMethod ? fetchMethod() : undefined;
    },
  },
  computed: {
    toggleScreenSize: function () {
      if (this.isMobileView) {
        return !this.learnMorePopupActive;
      }
      return true;
    },
  },
  watch: {
    page: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.alertBanner = [];
          if (newVal === "checkout") {
            return this.getBanners();
          }
          return this.fetchAlertBanners();
        }
      },
      immediate: true,
    },
    slug: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.alertBanner = [];
          this.fetchAlertBanners();
        }
      },
      immediate: true,
    },
    alertBanner: {
      handler(value) {
        this.updateHomepage(value.length > 0);
        this.$store.commit(
          "eCommerce/UPDATE_SHOW_ALERT_HEADER",
          value.length > 0
        );
      },
    },
    "$store.state.eCommerce.cartItems": {
      handler(value) {
        if (this.page === "checkout") {
          return this.getBanners();
        }
      },
    },
  },
  created() {
    this.fetchAlertBanners();
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
    this.updateHomepage(this.alertBanner.length > 0);
    this.$store.commit(
      "eCommerce/UPDATE_SHOW_ALERT_HEADER",
      this.alertBanner.length > 0
    );
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style lang="scss">
.learnMorePopup .vs-popup {
  width: 50%;
  overflow-wrap: break-word;
}

.con-vs-popup.fullscreen .vs-popup {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}
.shop-margin {
  margin-top: 4rem !important;
  @media screen and (max-width: 768px) {
    margin-top: 3.5rem !important;
  }
}
.shop-alert-margin {
  @media screen and (min-width: 768px) {
    margin-top: 8.5rem !important;
  }
}

.alert-description {
  ul,
  ol {
    padding-left: 2.5rem;
  }
  ul {
    list-style-type: initial;
  }
  ol {
    list-style-type: auto;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  address {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    unicode-bidi: isolate;
  }
  p {
    margin-block: 1em;
  }
  h1 {
    font-size: 2em;
    margin-block: 0.67em;
    font-weight: bold;
  }
  h2 {
    font-size: 1.5em;
    margin-block: 0.83em;
  }
  h3 {
    font-size: 1.17em;
    margin-block: 1em;
  }
  h4 {
    margin-block: 1.33em;
  }
  h5 {
    font-size: 0.83em;
    margin-block: 1.67em;
  }
  h6 {
    font-size: 0.67em;
    margin-block: 2.33em;
  }
  pre {
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
  }
  address {
    font-style: italic;
  }
}
</style>
